.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
}

h3 {
  margin-bottom: 20px;
  color: #333;
}

.card-form {
  cursor: pointer;
  transition: transform 0.3s;
  margin-top: 0px;
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(37, 16, 196, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.card-type{
  padding: 15px 0 0 0;
}
.card-type__label {
  font-size: 16px;
  color: #999999;
  float: left;
  width: auto;
  height: 20px;
  line-height: 20px;
}

.card-type__icons {
  text-align: center;
}
.card-type__icon {
  vertical-align: middle;
  padding: 0 5px;
}
.card-type__icon--disabled {
  filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  opacity: 0.5;
}

.notification {
  position: relative;
  padding: 15px 0;
  /* border-bottom: solid 1px #DDDDDD; */
}
.notification__icon {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  margin-left: 17px;
}
.notification__text {
  margin-left: 44px;
  display: block;
  color: #989898;
  font-size: 14px;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: flex-start;
}

.react-tabs__tab-panel {
  width: 100%; /* Remplacez cette valeur par la hauteur souhaitée */
  height: 320px; /* Remplacez cette valeur par la hauteur souhaitée */
}

.alert-danger {
  background-color: rgb(147, 7, 7);
  color: white;
  /* Autres styles de mise en forme */
}

.alert-success {
  background-color: rgb(22, 147, 6);
  color: white;
  /* Autres styles de mise en forme */
}

.gray-background {
  background-color: #f0f0f0;
}


.payment-form__button {
  margin-top: 20px;
  width: 100%
}