
  .paymentMobileMoneyForm .card-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .paymentMobileMoneyForm .card-input--full {
    margin-top: 20px;
  }
  
  .payment-MobileMoney-form .full-width-form {
    width: 100%;
  }

  .paymentMobileMoneyForm .mt-3 {
    margin-top: 1rem !important;
  }
  
  .payment-MobileMoney-form .payment-form__button {
    margin-top: 20px;
    width: 100%
  }
  
  .payment-MobileMoney-form .spinner-form-mobile-money {
    display: none;
  }

  .payment-MobileMoney-form .card {
    cursor: pointer;
    transition: transform 0.3s;
    margin: 0px;
    padding: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .payment-MobileMoney-form .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  /* .payment-MobileMoney-form .card-active {
    transform: scale(1.1);
    border: 2px solid #007bff;
  } */
  
  .payment-MobileMoney-form .card .card-img-top {
    width: 50px;
    margin: 0 auto;
  }
  
  .payment-MobileMoney-form .card-deck {
    display: flex;
    justify-content: space-around;
  }
  
  .payment-MobileMoney-form .operator-card .card-header {
    background-color: #f8f9fa;  /* Changer la couleur de fond selon vos préférences */
    color: #343a40; /* Changer la couleur du texte selon vos préférences */
    text-align: center; /* Centrer le texte */
    font-size: 18px; /* Changer la taille de la police selon vos préférences */
    border-bottom: 1px solid #dee2e6; /* Ajouter une bordure en bas */
}

.payment-MobileMoney-form .operator-card.active .card-header {
    background-color: #007bff; /* Changer la couleur de fond pour la card active */
    color: #ffffff; /* Changer la couleur du texte pour la card active */
}

/* .payment-MobileMoney-form .card-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    padding: 0px;
    margin: 10px;
    border-radius: 50%;
    background-color: #f0f0f0;
    transition: 0.3s;
  } */
  
  .payment-MobileMoney-form .card-avatar:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
.payment-MobileMoney-form .card-avatar,
.payment-MobileMoney-form .card-avatar-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;  /* Ajoutez ceci pour définir une hauteur spécifique */
    padding: 0px;
    margin: 10px;
    border-radius: 50%;
    overflow: hidden;
    /* autres styles */
}

.payment-MobileMoney-form .card-avatar-active {
    /* autres styles */
    border: 2px solid #2385e7cd;
    box-shadow: 0 8px 16px 0 #2385e7cd;
}

.payment-MobileMoney-form .card-avatar img,
.payment-MobileMoney-form .card-avatar-active img {
    position: absolute;  /* Ajoutez ceci */
    top: 50%;  /* Ajoutez ceci */
    left: 50%;  /* Ajoutez ceci */
    transform: translate(-50%, -50%);  /* Ajoutez ceci */
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    /* autres styles */
}
  


